import React, { ReactNode, useState, useContext } from "react";
// @ts-ignore
import { SpinnerCircular } from "spinners-react";
import "./Button.css";
import { ButtonGroupContext } from "./ButtonGroup";

export type ButtonProps = {
  code: string;
  children?: ReactNode;
  selected?: boolean;
  color?: any;
  spinning?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const Button = (props: ButtonProps) => {
  const [selected, setSelected] = useState(props.selected);
  const { toggleButton } = useContext(ButtonGroupContext);

  const classNames = ["Button"];
  if (selected) {
    classNames.push("selected");
  }

  const clicked = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      setSelected(!selected);
      toggleButton(props.code);
    }
  };

  return (
    <span
      style={{
        position: "relative",
      }}
    >
      {props.spinning && (
        <SpinnerCircular
          color="#fff"
          secondaryColor="#999"
          size={30}
          style={{
            position: "absolute",
            left: "8px",
            top: 0,
            zIndex: 200,
          }}
        />
      )}
      <span
        onClick={clicked}
        className={classNames.join(" ")}
        style={{
          opacity: props.disabled ? 0.5 : 1,
          backgroundColor: props.color || (selected ? "white" : "black"),
          paddingLeft: props.spinning ? "3rem" : "0.5rem",
        }}
      >
        {props.children}
      </span>
    </span>
  );
};

export default Button;
