import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <span>Copyright &copy; 2020 OurKink</span>
    </div>
  );
};

export default Footer;
