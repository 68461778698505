import React, { createContext, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";

const initialState = {
  surveyVisible: false,
  setSurveyVisible: (flag: boolean) => {},
  fields: {} as { [key: string]: any },
  setFields: (fields: {}) => {},
  emailVisible: true,
  setEmailVisible: (flag: boolean) => {},
  sending: false,
  setSending: (flag: boolean) => {},
  sent: false,
  setSent: (flag: boolean) => {},
  isMobile: false,
};

export const AppContextContext = createContext(initialState);

export const AppContext = (props: { children: any }) => {
  const { isMobile } = useWindowSize();
  const [surveyVisible, setSurveyVisible] = useState(false);
  const [fields, setFields] = useState({});
  const [emailVisible, setEmailVisible] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  return (
    <AppContextContext.Provider
      value={{
        surveyVisible,
        setSurveyVisible,
        fields,
        setFields,
        emailVisible,
        setEmailVisible,
        sending,
        setSending,
        isMobile,
        sent,
        setSent,
      }}
    >
      {props.children}
    </AppContextContext.Provider>
  );
};
