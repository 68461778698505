import React from "react";
import Transition, {
  EnterHandler,
  ExitHandler,
} from "react-transition-group/Transition";

type AnimatedProps = {
  property?: string | string[];
  in?: boolean;
  start?: any;
  end?: any;
  duration?: number;
  children: React.ReactNode;
  appear?: boolean;
  className?: any;
  style?: any;
  timeout?: number;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  onEntered?: EnterHandler;
  onExited?: ExitHandler;
};

const Animated = (props: AnimatedProps) => {
  let property = props.property || "opacity";
  let start = props.start || 0;
  let end = props.end || 1;
  const duration = props.duration || 1000;
  const inProp = props.in !== undefined ? props.in : props.appear;
  const appear = props.appear || false;

  property = Array.isArray(property) ? property : [property];
  start = Array.isArray(start) ? start : [start];
  end = Array.isArray(end) ? end : [end];

  const transitionString = commaSeparatePossArray(property, duration);

  let defaultStyle: { [key: string]: any } = {
    transition: `${transitionString} ease-in-out`,
  };

  addPropertiesFromArray(defaultStyle, property, start);

  const transitionStyles: { [key: string]: any } = {
    entering: addPropertiesFromArray({}, property, start),
    entered: addPropertiesFromArray({}, property, end),
    exiting: addPropertiesFromArray({}, property, end),
    exited: addPropertiesFromArray({}, property, start),
  };

  return (
    <Transition
      in={inProp}
      appear={appear}
      timeout={props.timeout || 0}
      className={props.className}
      mountOnEnter={props.mountOnEnter}
      unmountOnExit={props.unmountOnExit}
      onEntered={props.onEntered}
      onExited={props.onExited}
    >
      {(state) => (
        <div
          style={{
            ...props.style,
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={props.className}
        >
          {props.children}
        </div>
      )}
    </Transition>
  );
};

function commaSeparatePossArray(property: string | string[], duration: number) {
  if (typeof property === "string") {
    return `${property} ${duration}ms`;
  }

  let props = [];
  for (let i = 0; i < property.length; i++) {
    props.push(property[i] + " " + duration + "ms");
  }
  return props.join(", ");
}

function addPropertiesFromArray(
  obj: { [key: string]: any },
  properties: string[],
  starts: any[]
) {
  for (let i = 0; i < properties.length; i++) {
    obj[properties[i]] = starts[i];
  }
  return obj;
}

export default Animated;
