import React from "react";
import ShareButton from "./ShareButton";
import twitter from "../images/twitter.png";
import tumblr from "../images/tumblr-white.png";
import facebook from "../images/facebook.png";
import BDSMLR from "../images/BDSMLR.png";
import "./ShareButtons.css";

//www.tumblr.com/share/link?url=http%3A%2F%ourkink.uk/&description=Guerilla%20Labs%20does%20amazing%20work%20and%20you%20should%20check%20them%20out.

type ShareButtonsProps = {
  text: string;
  size: "large" | "small";
  caption?: string;
};

const ShareButtons = (props: ShareButtonsProps) => {
  const text = encodeURI(props.text);
  let className = "ShareButtons";
  if (props.size === "small") {
    className += " small-buttons";
  }
  return (
    <div className="ShareButtons_container">
      <div className={className}>
        <ShareButton
          imageFile={twitter}
          network="Twitter"
          url={
            "https://twitter.com/intent/tweet?url=https%3A%2F%2Fourkink.uk&text=" +
            text
          }
          includeCaption={props.size === "large"}
        />
        <ShareButton
          imageFile={tumblr}
          backgroundColor="rgb(0, 25, 53)"
          percentWidth={75}
          network="Tumblr"
          url={
            "http://www.tumblr.com/share/link?url=https%3A%2F%2Fourkink.uk&description=" +
            text
          }
          includeCaption={props.size === "large"}
        />
        <ShareButton
          imageFile={facebook}
          network="Facebook"
          url="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fourkink.uk"
          includeCaption={props.size === "large"}
        />
        <ShareButton
          imageFile={BDSMLR}
          network="BDSMLR"
          backgroundColor="red"
          percentWidth={80}
          url="https://bdsmlr.com"
          includeCaption={props.size === "large"}
        />
      </div>

      {props.caption && <p style={{ fontSize: "70%" }}>{props.caption}</p>}
    </div>
  );
};

export default ShareButtons;
