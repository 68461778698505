import React from "react";
import "./ShareButton.css";

type ShareButtonProps = {
  imageFile: any;
  network: string;
  backgroundColor?: any;
  percentWidth?: number;
  url: string;
  includeCaption?: boolean;
};

const ShareButton = (props: ShareButtonProps) => {
  let containerClass = "img-container";
  if (props.backgroundColor) {
    containerClass += " with-background";
  }

  return (
    <div className="ShareButton">
      <a target="_blank" rel="noreferrer noopener" href={props.url}>
        <div
          className={containerClass}
          style={{
            backgroundColor: props.backgroundColor,
          }}
        >
          <img
            src={props.imageFile}
            style={{
              width: props.percentWidth + "%",
            }}
            alt=""
          />
        </div>
        {props.includeCaption && <p>Share on {props.network}!</p>}
      </a>
    </div>
  );
};

export default ShareButton;
