import React, { createContext, useState } from "react";

export const ButtonGroupContext = createContext({
  selectedButtons: [],
  toggleButton: (code: string) => {},
});

type ButtonGroupProps = {
  children?: any;
  onChange?: (selected: string[]) => void;
};

const ButtonGroup = (props: ButtonGroupProps) => {
  const [selected, setSelected] = useState([]);

  // TODO: this sets initially selected buttons, but was
  // triggering every time and clearning the selected array
  // useEffect(() => {
  //   const selectedButtons = React.Children.map(
  //     props.children,
  //     (child: ReactElement<ButtonProps>) => {
  //       if ((child as ReactElement<ButtonProps>).props.selected) {
  //         return child.props.code as never;
  //       }
  //     }
  //   );
  //   setSelected(selectedButtons);
  // }, [props.children]); // should this be [] ?

  const toggleButton = (code: string) => {
    let selectedCopy = [...selected];
    addOrRemove(selectedCopy, code);
    setSelected(selectedCopy);
    if (props.onChange) {
      props.onChange(selectedCopy);
    }
  };

  return (
    <ButtonGroupContext.Provider
      value={{
        selectedButtons: [],
        toggleButton,
      }}
    >
      <div className="ButtonGroup">{props.children}</div>
    </ButtonGroupContext.Provider>
  );
};

// helpers

function addOrRemove(array: string[], value: string) {
  var index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  console.log("array", array);
}

export default ButtonGroup;
