import image3 from "../images/3.jpg";
import image4 from "../images/4.jpg";
import image6 from "../images/6.jpg";
import image8 from "../images/8.jpg";
import image10 from "../images/10.jpg";
import download from "../images/download.png";
//import dscf0015 from "../images/DSCF0015.JPG";
import fsuit_n_bj from "../images/fsuit_n_bj.jpeg";
//import img_0330 from "../images/IMG_0330.JPG";
import ma1mar21 from "../images/ma1-mar21.jpg";
import sitting_on_post from "../images/Sitting_on_post.jpg";
import dscf0063 from "../images/dscf0063.jpg";

const images = [
  image3,
  image4,
  image6,
  image8,
  image10,
  download,
  //dscf0015,
  fsuit_n_bj,
  //img_0330,
  ma1mar21,
  sitting_on_post,
  dscf0063,
];

export const getRandomImages = (debug?: string) => {
  if (debug) {
    console.log("getting random images: " + debug);
  }
  return images.sort(() => 0.5 - Math.random());
};
