import React from "react";
import "./Header.css";
import menuBars from "../images/menu-bars.svg";
import Animated from "./Animated";

const Header = () => {
  return (
    <div className="header">
      <div style={{ overflow: "hidden" }}>
        <Animated
          appear={true}
          timeout={1000}
          property="transform"
          start="translateY(100px)"
          end="translateY(0)"
        >
          <h1>
            <img src={menuBars} className="bars" alt="" />
            <strong>Our</strong>Kink
            <img src={menuBars} className="bars" alt="" />
          </h1>
        </Animated>
      </div>

      <div style={{ overflow: "hidden" }}>
        <Animated
          appear={true}
          timeout={1500}
          property="opacity"
          start={0}
          end={1}
        >
          <h2>
            creating unique <strong>kink</strong> focused events for{" "}
            <strong>our</strong> kinky gay community
            <br />
            <span className="exclusive">exclusively for kinky gay men</span>
          </h2>
        </Animated>
      </div>
    </div>
  );
};

export default Header;
