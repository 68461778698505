import React from "react";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Background from "./components/Background";
import { ImageRow } from "./components/ImageRow";
import { useWindowSize } from "./hooks/useWindowSize";
import { getRandomImages } from "./services/randomImages";

import { AppContext } from "./contexts/appContext";

export const App = () => {
  const { isMobile } = useWindowSize();
  const images = getRandomImages("app");

  console.log("in app");

  return (
    <AppContext>
      <Background>
        <Header />
        {isMobile && <ImageRow imageArray={images} numImages={4} />}
        <Body />
        {isMobile && (
          <ImageRow imageArray={images} numImages={4} imagesFromEnd={true} />
        )}
        <Footer />
      </Background>
    </AppContext>
  );
};
