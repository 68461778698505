import React from "react";
import "./Body.css";
import EmailBar from "./EmailBar";
import Survey from "./Survey";
import ShareButtons from "./ShareButtons";

// type BodyProps = {
//   setSurveyVisible: (flag: boolean) => {};
// };

const Body = (props: any) => {
  return (
    <div className="body">
      <div>
        <div style={{ textAlign: "center" }}>
          <p>
            <strong>Welcome!</strong> We are looking to create an event for gay
            guys into bomber jackets and silky gear.
          </p>
          <p>
            Bomber jackets, Alpha Industries gear, alpha flight suits, down
            jackets, Adidas Chile 62 tracksuits, Adidas german army shorts
            &ndash; gear that is <strong>silky and shines</strong>.
          </p>
          <p>Please signup for our updates and take our mini survey:</p>

          <EmailBar />

          <p>
            Don’t worry we will not use your address for any other reason than
            information about ourkink.uk events and you can unsubscribe at
            anytime.
          </p>
          <p>
            Want to see your pictures on this site, send them to{" "}
            <a href="mailto:ourkinkpics@ourkink.uk">ourkinkpics@ourkink.uk</a>;
            it's your gear not your dick we want to see and show.
          </p>
          <ShareButtons
            text="Our Kink - Silky Gear - Events"
            size="small"
            caption="Help us get the word out! Share to your networks."
          />
        </div>

        <Survey />
      </div>
    </div>
  );
};

export default Body;
