import React from "react";
import Animated from "./Animated";
import "./ImageRow.css";

type ImageRowProps = {
  imageArray: string[];
  imagesFromEnd?: boolean;
  numImages: number;
};

export const ImageRow = (props: ImageRowProps) => {
  let images: string[];
  if (props.imagesFromEnd) {
    console.log("from end: true");
    images = props.imageArray.slice(5, 10);
  } else {
    console.log("from end: false");
    images = props.imageArray.slice(0, 5);
  }

  let i = 0;

  return (
    <div className="ImageRow">
      {images.map((image) => (
        <div
          className="imageContainer"
          style={{ left: i * 20 - 5 + "%" }}
          key={i++}
        >
          <Animated
            appear={true}
            property={["transform", "opacity"]}
            start={["scale(0, 0)", 0]}
            end={["scale(1, 1)", 1]}
            timeout={Math.random() * (3000 - 2000) + 2000}
            duration={2000}
          >
            <img className="image" src={image} alt="" />
          </Animated>
        </div>
      ))}
    </div>
  );
};
