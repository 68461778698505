import React from "react";
import "./BoxedImage.css";
import Animated from "./Animated";

type BoxedImageProps = {
  src: string;
  alt?: string;
  className?: string;
  style?: any;
};

export const BoxedImage = (props: BoxedImageProps) => {
  return (
    <Animated
      appear={true}
      property={["transform", "opacity"]}
      start={["scale(0, 0)", 0]}
      end={["scale(1, 1)", 1]}
      timeout={Math.random() * (3000 - 2000) + 2000}
      className={"BoxedImage " + props.className}
      duration={2000}
      style={props.style}
    >
      <img src={props.src} alt={props.alt} />
    </Animated>
  );
};
