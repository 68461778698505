import React from "react";
import "./SurveyInput.css";

type SurveyInputProps = {
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  style?: any;
  error?: string;
};

const SurveyInput = (props: SurveyInputProps) => {
  return (
    <div className="SurveyInput">
      <input
        name={props.name}
        placeholder={props.placeholder || ""}
        value={props.value}
        onChange={props.onChange}
        style={props.style}
      />
      {props.error && <p className="error">{props.error}</p>}
    </div>
  );
};

export default SurveyInput;
