import { useLayoutEffect, useState } from "react";

export function useWindowSize() {
  //const [size, setSize] = useState({ width: 0, height: 0, isMobile: false });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  //console.log("UWS");

  useLayoutEffect(() => {
    const updateSize = () => {
      console.log(`updateSize: w=${window.innerWidth} h=${window.innerHeight}`);
      const lowWidth = window.matchMedia("(max-width: 800px)").matches;

      if (window.innerWidth !== width) {
        console.log("changing width");
        setWidth(window.innerWidth);
      }

      if (isMobile !== lowWidth) {
        console.log("changing isMobile");
        setIsMobile(lowWidth);
      }

      if (window.innerHeight !== height) {
        console.log("changing height");
        setHeight(window.innerHeight);
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [height, width, isMobile]);

  return { width, height, isMobile };
}
