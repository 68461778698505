import React, { useContext, useState } from "react";
import "./Survey.css";
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
import SurveyInput from "./SurveyInput";
import ShareButtons from "./ShareButtons";
import Animated from "./Animated";
import { AppContextContext } from "../contexts/appContext";

const Survey = () => {
  const {
    fields,
    setFields,
    surveyVisible,
    setSurveyVisible,
    emailVisible,
    sending,
    setSending,
    sent,
    setSent,
  } = useContext(AppContextContext);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const submitForm = () => {
    // console.log(JSON.stringify({ email }));
    setSending(true);
    fetch(`https://ourkink.uk/send-email/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apikey: "GG76h78jhTG54k9pJNE237B",
        appkey: "ourkink-form",
        ...fields,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSending(false);
        if (data.errors) {
          console.log("Error response: " + JSON.stringify(data.errors));
          setErrors(data.errors);
        } else {
          setSent(true);
          setFields({});
          console.log(data);
        }
      })
      .catch((error) => {
        setSending(false);
        console.error("Other error: ", error);
      });
  };

  return (
    <Animated
      in={surveyVisible}
      appear={false}
      property="top"
      start="-100%"
      end={0}
      className="Survey"
      mountOnEnter={true}
    >
      <div className="survey-content">
        {sent ? (
          <>
            <h1>Thank you!</h1>
            <p>
              Your responses will really help us putting together great{" "}
              <strong>kinky</strong> events!
            </p>
            <ShareButtons
              text="Checkout this ourkink page, I want to go, are you coming?"
              size="large"
            />
            <p className="bottomButtons">
              <Button
                color="red"
                code="cancel"
                onClick={() => {
                  setSurveyVisible(false);
                }}
              >
                Close
              </Button>
            </p>
          </>
        ) : (
          <>
            {errors._all && (
              <p className="error">Please fill in some information!</p>
            )}
            <h4>
              Thank you for taking this survey and helping us create the event
              that you would want to come to.
            </h4>
            {emailVisible && (
              <div className="question">
                <h4>
                  Your email address (we will never use this for anything else):
                </h4>
                <SurveyInput
                  name="email"
                  style={{ width: "50%" }}
                  error={errors.email || null}
                  onChange={(input: any) => {
                    setFields({ ...fields, email: input.target.value });
                  }}
                  value={fields.email}
                  placeholder="gear@ourkink.uk"
                />
              </div>
            )}
            <div className="question">
              <h4>Where would you like the event?</h4>
              <ButtonGroup
                onChange={(selected) =>
                  setFields({ ...fields, locations: selected })
                }
              >
                <Button code="cardiff">Cardiff</Button>
                <Button code="london">London</Button>
                <Button code="birmingham">Birmingham</Button>
                <Button code="edinburgh">Edinburgh</Button>
              </ButtonGroup>
            </div>
            <div className="question">
              <h4>Type of event?</h4>
              <ButtonGroup
                onChange={(selected) =>
                  setFields({ ...fields, type: selected })
                }
              >
                <Button code="single">
                  <strong>single venue:</strong> with group dinners, private
                  space, own bar; in a hotel or similar
                </Button>

                <Button code="multi">
                  <strong>multi-venue:</strong> suggested hotel but not
                  exclusive, events in local cafes, bars, night clubs
                </Button>
              </ButtonGroup>
            </div>
            <div className="question">
              <h4>Any other thoughts or comments?</h4>
              <SurveyInput
                style={{ width: "100%" }}
                name="comments"
                value={fields.comments}
                error={errors.comments || null}
                onChange={(input: any) => {
                  setFields({ ...fields, comments: input.target.value });
                }}
              />
            </div>
            <div className="question">
              <h4>
                We are initially looking to put on a silky gear event. Are there
                other kink events you would like?
              </h4>
              <SurveyInput
                style={{ width: "100%" }}
                name="other"
                placeholder="eg. pup, play, furries"
                value={fields.other}
                error={errors.other || null}
                onChange={(input: any) => {
                  setFields({ ...fields, other: input.target.value });
                }}
              />
            </div>
            <p className="bottomButtons">
              <Button
                color="green"
                code="submit"
                spinning={sending}
                disabled={sending}
                onClick={submitForm}
              >
                Submit
              </Button>
              <Button
                color="red"
                code="cancel"
                disabled={sending}
                onClick={() => {
                  setSurveyVisible(false);
                }}
              >
                Cancel
              </Button>
            </p>
          </>
        )}
      </div>
    </Animated>
  );
};

export default Survey;
