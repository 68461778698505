import React, { ReactNode } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import "./Background.css";
import { BoxedImage } from "./BoxedImage";
import { getRandomImages } from "../services/randomImages";

type BackgroundProps = {
  children: ReactNode;
};

type ImageArray = {
  img: any;
  y: number;
  x: number;
}[];

const Background = (props: BackgroundProps) => {
  const { isMobile } = useWindowSize();

  console.log("in background");

  let sideImages: any;
  if (!isMobile) {
    const randomImages = getRandomImages("background");
    const xPos = isMobile ? -5 : 0;
    const xPosVariance = 10;

    let vertical = -5;
    const leftImages = randomImages.slice(0, randomImages.length / 2);
    const leftImagesWithPos: ImageArray = [];
    for (const img of leftImages) {
      leftImagesWithPos.push({
        img,
        y: vertical,
        x: Math.random() * xPosVariance - xPosVariance / 2,
      });
      vertical += 20;
    }

    vertical = -5;
    const rightImages = randomImages.slice(randomImages.length / 2);
    const rightImagesWithPos: ImageArray = [];
    for (const img of rightImages) {
      rightImagesWithPos.push({
        img,
        y: vertical,
        x: Math.random() * xPosVariance - xPosVariance / 2,
      });
      vertical += 20;
    }

    sideImages = (
      <>
        <div className="leftImages">
          {leftImagesWithPos.map((item) => (
            <BoxedImage
              style={{
                top: item.y + "vh",
                left: item.x + xPos + "vh",
              }}
              src={item.img}
              key={item.img}
            />
          ))}
        </div>

        <div className="rightImages">
          {rightImagesWithPos.map((item) => (
            <BoxedImage
              style={{
                top: item.y + "vh",
                right: item.x + xPos + "vh",
              }}
              src={item.img}
              key={item.img}
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="Background">
      <div className="background">{!isMobile && sideImages}</div>
      <div className="overlay">{props.children}</div>
    </div>
  );
};

export default Background;
