import React, { useContext } from "react";
import "./EmailBar.css";
import { AppContextContext } from "../contexts/appContext";

// type EmailBarProps = {
//   setSurveyVisible: (flag: boolean) => {};
// };

const EmailBar = (props: any) => {
  const {
    fields,
    setFields,
    setSent,
    setSurveyVisible,
    setEmailVisible,
  } = useContext(AppContextContext);

  return (
    <div className="EmailBar">
      <input
        onChange={(input) =>
          setFields({ ...fields, email: input.target.value })
        }
        placeholder="Email"
        value={fields.email || ""}
      />
      <button
        onClick={() => {
          setSurveyVisible(true);
          setEmailVisible(true);
          setSent(false);
        }}
      >
        OK
      </button>
      <button
        onClick={() => {
          setSurveyVisible(true);
          setEmailVisible(false);
          setSent(false);
        }}
      >
        SURVEY ONLY
      </button>
    </div>
  );
};

export default EmailBar;
